import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { inject, observer } from 'mobx-react'
import { APIURL } from '../../constants'
import services from '../services'
import LoadingButton from '../common/LoadingButton'
import { Formik, Form, Field } from 'formik'
import FormikField from '../forms/FormikField'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import styles from './Advisor.module.scss'

toast.configure()

const changePasswordValues = {
  current_password: '',
  new_password: '',
  confirm_password: ''
}

const setPasswordValues = {
  new_password: '',
  confirm_password: ''
}

const password_strength =
  /^^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/

export const forgotPasswordSchema = {
  email: Yup.string().email('Invalid email').required('Required')
}

export const resetPasswordSchema = {
  new_password: Yup.string()
    .matches(
      password_strength,
      'Password should not be less than 6 characters. Password should contain at least one upper case, one lower case, one number and one special character.'
    )
    .required('Required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Required')
}

const changePasswordSchema = {
  current_password: Yup.string().required('Required'),
  new_password: Yup.string()
    .matches(
      password_strength,
      'Password should not be less than 6 characters. Password should contain at least one upper case, one lower case, one number and one special character.'
    )
    .required('Required'),
  confirm_password: Yup.string().oneOf(
    [Yup.ref('new_password'), null],
    'Passwords must match'
  )
}

const setPasswordSchema = {
  new_password: Yup.string()
    .matches(
      password_strength,
      'Password should not be less than 6 characters. Password should contain at least one upper case, one lower case, one number and one special character.'
    )
    .required('Required'),
  confirm_password: Yup.string().oneOf(
    [Yup.ref('new_password'), null],
    'Passwords must match'
  )
}

const changePasswordForm = (
  <div>
    <div className="row clearfix">
      <div className="col-md-12">
        <FormikField name="current_password" label="" required={false}>
          <Field
            className="form-control"
            name="current_password"
            placeholder="Current Password"
            type="password"
          />
        </FormikField>
      </div>
    </div>
    <div className="row clearfix">
      <div className="col-md-12">
        <FormikField name="new_password" label="" required={false}>
          <Field
            className="form-control"
            name="new_password"
            placeholder="New Password"
            type="password"
          />
        </FormikField>
      </div>
    </div>
    <div className="row clearfix">
      <div className="col-md-12">
        <FormikField name="confirm_password" label="" required={false}>
          <Field
            className="form-control"
            name="confirm_password"
            placeholder="Confirm Password"
            type="password"
          />
        </FormikField>
      </div>
    </div>
  </div>
)

const setPasswordForm = (
  <div>
    <div className="row clearfix">
      <div className="col-md-12">
        <FormikField name="new_password" label="" required={false}>
          <Field
            className="form-control"
            name="new_password"
            placeholder="New Password"
            type="password"
          />
        </FormikField>
      </div>
    </div>
    <div className="row clearfix">
      <div className="col-md-12">
        <FormikField name="confirm_password" label="" required={false}>
          <Field
            className="form-control"
            name="confirm_password"
            placeholder="Confirm Password"
            type="password"
          />
        </FormikField>
      </div>
    </div>
  </div>
)

@inject('store')
@observer
class ChangePassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading_data: false,
      change_set: this.props.change_set || 'change'
    }
  }

  async handleSave(values) {
    try {
      this.setState({
        loading_data: true
      })
      let res = null
      if (this.state.change_set === 'change') {
        res = await services.post_v2(APIURL.API_USER_CHANGE_PASSWORD, values)
      } else if (this.state.change_set === 'verify') {
        values['signup_token'] = this.props.signup_token
        res = await services.post_v2(APIURL.API_TOKEN_SET_PASSWORD, values)
      } else if (this.state.change_set === 'set') {
        res = await services.post_v2(
          APIURL.API_ADMIN_USER_SET_PASSWORD(this.props.user_id),
          values
        )
      }
      if (res.statusCode === 200) {
        toast('Password successfully updated')
        this.props.validated(true)
      } else {
        if (res.message) {
          toast.error(res.message)
        } else {
          toast.error('Password update failed')
        }
      }
      this.setState({
        loading_data: false
      })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const change_set = this.state.change_set
    const vals =
      change_set === 'change' ? changePasswordValues : setPasswordValues
    const schema =
      change_set === 'change' ? changePasswordSchema : setPasswordSchema
    const form = change_set === 'change' ? changePasswordForm : setPasswordForm
    return (
      <div className="tab-pane active" id="home">
        <h2 style={{ textAlign: 'center' }}>{this.props.title}</h2>
        <div className="well-container clearfix">
          <div className="tab-content">
            <div className="tab-pane active" id="home">
              <Formik
                initialValues={vals}
                validationSchema={Yup.object(schema)}
                onSubmit={async (values, { setSubmitting }) => {
                  await this.handleSave(values)
                  setSubmitting(false)
                }}
              >
                <Form>
                  <div>
                    {form}
                    <div className="row clearfix">
                      <div className="col-sm-4"></div>
                      <div className="col-sm-4">
                        <LoadingButton
                          text="Save"
                          isLoading={this.state.loading_data}
                        />
                      </div>
                      <div className="col-sm-4"></div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ChangePassword.propTypes = {
  store: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  title: PropTypes.string,
  change_set: PropTypes.string,
  signup_token: PropTypes.string,
  validated: PropTypes.func,
  user_id: PropTypes.string
}

export default withRouter(ChangePassword)
