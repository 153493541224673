import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { inject, observer } from 'mobx-react'
import * as Yup from 'yup'
import styled from 'styled-components'

import services from '../services'
import { APIURL, PAGEURL } from '../../constants'
import { forgotPasswordSchema } from '../advisor/ChangePassword'
import PageTitle from '../common/PageTitle'
import LoadingButton from '../common/LoadingButton'

const FormWrapper = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Row = styled.div`
  margin: 10px 0;
  flex: 0 1 auto;
`

const RowCenter = styled(Row)`
  text-align: center;
`

const ErrorMessageWrapper = styled.div`
  color: ${(props) => props.theme.colors.danger};
  padding: 10px 0;
  font-size: 0.8rem;
`

const Login = inject('store')(
  observer(({ store }) => {
    const { siteData } = store
    const router = useRouter()
    const title = `${siteData.get('name')} - Login`
    return (
      <div>
        <PageTitle title={title} />
        <FormWrapper>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={Yup.object(forgotPasswordSchema)}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              setSubmitting(true)
              try {
                const data = {
                  email: values.email,
                  password: values.password
                }
                const res = await services.post_v2(APIURL.API_USER_LOGIN, data)
                if (res.statusCode === 200) {
                  const { unique_code, login_token, userData } = res.response
                  store.login(unique_code, login_token, userData)
                  router.push(PAGEURL.DASHBOARD)
                } else {
                  setErrors({ email: res.message })
                  setSubmitting(false)
                }
              } catch (e) {
                console.error(e)
                setErrors({
                  email: 'Sorry, we are experiencing issues right now'
                })
                setSubmitting(false)
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="form-control"
                    autoFocus
                  />
                  <ErrorMessage name="email" component={ErrorMessageWrapper} />
                </Row>
                <Row>
                  <Field
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="password"
                    component={ErrorMessageWrapper}
                  />
                </Row>
                <RowCenter>
                  <Link href={PAGEURL.FORGOT_PASSWORD}>
                    <a>Don&apos;t remember your password?</a>
                  </Link>
                </RowCenter>
                <RowCenter>
                  <LoadingButton
                    text="Login"
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  />
                </RowCenter>
              </Form>
            )}
          </Formik>
          {siteData.get('allow_signup') && (
            <RowCenter>
              Don&apos;t have an account yet? <br />
              <Link href={PAGEURL.SIGNUP}>
                <a>Become a Member</a>
              </Link>
            </RowCenter>
          )}
        </FormWrapper>
      </div>
    )
  })
)

export default Login
