import EmptyLayout from '/components/layouts/EmptyLayout'
import Login from '/components/profile/Login'
import { withAuthConditions } from '/components/utils'

Login.Layout = EmptyLayout

export const getServerSideProps = withAuthConditions(
  (ctx, siteData) => {
    return {
      props: {
        siteData
      }
    }
  },
  'local',
  'login'
)

export default Login
